import React from "react";
import * as Styled from "./paragraphWithImageStyles";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import Blueline from "../blueLine/blueLine";
import ContentfulAssets from "../../hooks/useContentfulAssets";

const paragraphWithImage = (sectionData) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.Paragraph>{children}</Styled.Paragraph>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = ContentfulAssets(node?.data?.target?.sys?.id);
        if (asset) {
          return (
            <Styled.PDFLink href={asset?.node?.file?.url} target="_self">
              Learn More
            </Styled.PDFLink>
          );
        }
      },
    },

    renderText: (text) =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
  };

  return (
    <Styled.MainDiv>
      {sectionData?.sectionData?.listOfImagesWithPath?.map((item, index) => {
        if (item != null) {
          if (item?.type === "leftSideImage") {
            return (
              <Styled.GridRowLeft>
                <Styled.ImageContainer>
                  <Styled.Image
                    src={item?.image?.file?.url}
                    alt="img"
                  ></Styled.Image>
                </Styled.ImageContainer>
                <Styled.TextContainer>
                  <Blueline />
                  <Styled.Header1>{item?.title}</Styled.Header1>
                  {item?.richText?.raw &&
                    documentToReactComponents(
                      JSON.parse(item?.richText?.raw),
                      optionsMainStyle
                    )}
                </Styled.TextContainer>
              </Styled.GridRowLeft>
            );
          } else {
            return (
              <Styled.GridRowRight>
                <Styled.TextContainer>
                  <Blueline />
                  <Styled.Header1>{item?.title}</Styled.Header1>
                  {item?.richText?.raw &&
                    documentToReactComponents(
                      JSON.parse(item?.richText?.raw),
                      optionsMainStyle
                    )}
                </Styled.TextContainer>
                <Styled.ImageContainer>
                  <Styled.Image
                    src={item?.image?.file?.url}
                    alt="img"
                  ></Styled.Image>
                </Styled.ImageContainer>
              </Styled.GridRowRight>
            );
          }
        }
        return <></>;
      })}
    </Styled.MainDiv>
  );
};

export default paragraphWithImage;
